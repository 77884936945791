import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL = "https://replica.highpolar.in/v1v1/notification";

class NotificationsService {
  getNotifications(access: string) {
    return axios.get(API_ENDPOINTS.Notifications.notifications,
      {
        headers: {
          Authorization: "Bearer " + access
        }  
      }
    );
  }
}

export default new NotificationsService();
