import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";

const API_URL = "https://replica.highpolar.in/v1v1/users";

class UsersService {
  create(
    name: string,
    email: string,
    password: string,
    phone_number: string,
    language: string,
    identity_document: string,
    referral_code: string
  ) {
    return axios.post(API_ENDPOINTS.User.user, {
      name,
      email,
      password,
      phone_number,
      language,
      identity_document,
      referral_code,
    });
  }

  createCopy(name: string, email: string, password: string) {
    return axios.get(API_ENDPOINTS.User.user, {
      params: {
        name,
        email,
        password,
      },
    });
  }

  getAll(access: string) {
    // email: string, password: string
    return axios.get(API_ENDPOINTS.User.user, {
      headers: {
        Authorization: "Bearer " + access,
      },
      // params: {
      //   email,
      //   password
      // }
    });
  }

  getProfile(user_id: string, access: string) {
    return axios.get(API_ENDPOINTS.User.user + "/" + user_id, {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }

  forgotPassword(email: string) {
    return axios.post(API_ENDPOINTS.User.forgot_password_email_link, {
      email,
    });
  }

  changePassword(data: {
    old_password: string;
    new_password: string;
    access: string;
  }) {
    return axios.post(
      API_ENDPOINTS.User.change_password,
      {
        old_password: data.old_password,
        new_password: data.new_password,
      },
      {
        headers: {
          Authorization: "Bearer " + data.access,
        },
      }
    );
  }

  updateProfile(user_id: string, access: string, field: any) {
    let data = new FormData();
    data.append('name', field.name);
    data.append('email', field.email);
    data.append('phone_number', field.phone_number);
    if (field.profile_photo != null) {
      data.append("profile_photo", field.profile_photo);
    }
    return axios.patch(API_ENDPOINTS.User.user + "/" + user_id, data, {
      headers: {
        Authorization: "Bearer " + access,
      },
    });
  }
}

export default new UsersService();
