import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL =
  "https://replica.highpolar.in/v1v1/investments";

class InvestmentsService {
  get(
    asset: string,
    purchased_price: string,
    purchased_quantity: string,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentsService.investments, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        asset,
        purchased_price,
        purchased_quantity
      }
    });
  }

  sell(
    asset_id: string,
    asset_quantity: string,
    access: string
  ) {
    return axios.post(
      API_ENDPOINTS.InvestmentsService.Sell,
      {
        asset_id,
        asset_quantity
      },
      {
        headers: {
          Authorization: "Bearer " + access
        }
      }
    );
  }

  buy(asset_id: string, asset_quantity: string, access: string) {
    return axios.post(
      API_ENDPOINTS.InvestmentsService.Buy,
      {
        asset_id,
        asset_quantity
      },
      {
        headers: {
          Authorization: "Bearer " + access
        }
      }
    );
  }

  getTotal(query: any, access: string) {
    return axios.get(API_ENDPOINTS.InvestmentsService.total_investment, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        query
      }
    });
  }

  getInvestments(
    page: number,
    page_size: number,
    access: string
  ) {
    return axios.get(API_ENDPOINTS.InvestmentsService.investments, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        page,
        page_size,
      }
    });
  }

  totalInvestmentByAsset(asset_id: any, access: string) {
    return axios.get(API_ENDPOINTS.InvestmentsService.total_investment, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {
        asset_id: asset_id
      }
    });
  }
}

export default new InvestmentsService();
