import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


// const API_URL = "http://localhost:8080/api/auth/";
const API_URL =
  "https://replica.highpolar.in/v1v1/auth/";

class AuthService {
  login(email: string, password: string) {
    return axios.post(API_ENDPOINTS.Auth.login, {
      email,
      password
    });
  }

  logout() {
    // localStorage.removeItem("user");
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_ENDPOINTS.Auth.signup, {
      username,
      email,
      password
    });
  }

  getBalance(
    access: string
  ) {
    return axios.get(API_ENDPOINTS.Balance.getBalance, {
      headers: {
        Authorization: "Bearer " + access
      },
      params: {}
    });
  }
}

export default new AuthService();
