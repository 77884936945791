import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL =
  "https://replica.highpolar.in/v1v1/profile";

class ProfileService {
  getProfile(access: string) {
    // email: string, password: string,
    return axios.get(API_ENDPOINTS.Profile.profile, {
      headers: {
        Authorization: "Bearer " + access
      } // ,
      // params: {
      //   email,
      //   password
      // }
    });
  }
  upDateProfile(access: string) {
    // email: string, password: string,
    return axios.patch(API_ENDPOINTS.Profile.update_profile,{}, {
      headers: {
        Authorization: "Bearer " + access
      } // ,
      // params: {
      //   email,
      //   password
      // }
    });
  }
}

export default new ProfileService();