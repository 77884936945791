import axios from "axios";
import { API_ENDPOINTS } from "./ApiEndpoints";


const API_URL =
  "https://replica.highpolar.in/v1v1/wallet";

class WalletService {
  get(access: string) {
    // email: string, password: string,
    return axios.get(API_ENDPOINTS.Wallet.wallet, {
      headers: {
        Authorization: "Bearer " + access
      } // ,
      // params: {
      //   email,
      //   password
      // }
    });
  }
}

export default new WalletService();
